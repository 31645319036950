export const apiConfig = {
    powerPointEndPoint: 'https://idpowertoys.azurewebsites.net'
}

export const msalConfig = {
    clientId: '6ce0484b-2ae6-4458-b2b9-b3369f42fd6f'
}

export const envConfig = {
    envName: ''
}
